import React from "react"
import MarkdownViewer from "@components/MarkdownViewer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import "./Instagram.scss"

var classNames = require("classnames")

function Instagram(props) {
  let insta = props.post.instagram

  if (insta.user && insta.hasThisSection) {
    let user =
      insta.user.charAt(0) === "@" ? insta.user.substring(1) : insta.user
    let link = `https://www.instagram.com/${user}`
    return (
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column instagram-container">
          <div className="insta-blurb">{insta.blurb}</div>
          <div className="insta-name">
            <div>
              <a
                href={link}
                title={`Instagram - ${insta.name}`}
                className="insta-icon"
                target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            <div>
              {insta.name}{" "}
              <a
                href={link}
                title={`Instagram - ${insta.name}`}
                target="_blank">{`@${user}`}</a>
            </div>
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Instagram
